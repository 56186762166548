import React, { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import PillButton from 'components/atoms/Buttons/PillButton';
import Topbar from 'components/organismos/Mypage/Topbar';
import GridTable from 'components/organismos/Mypage/GridTable';
import { Grid, TableCell } from '@material-ui/core';
import { GridTableColumn } from 'components/organismos/Mypage/GridTable/interface';
import Box from '@material-ui/core/Box';
import DataTable from 'components/organismos/DataTable';
import { DataTableColumn } from 'components/organismos/DataTable/interface';
import TopbarDesktop from 'components/organismos/Mypage/Topbar/desktop';
import useStyles from './style';

export interface CustomerReservation {
  reservationDateTime: string
  storeName: string
}

export interface CustomerReservationProps {
  customerData: CustomerReservation[]
  columns: DataTableColumn[]
}

const MypageReservationListDesktop: React.FC<CustomerReservationProps> = (p: CustomerReservationProps) => {
  const [isSubmit, setSubmit] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="600px"
        width="90%"
      >
        <TopbarDesktop />
        <Box display="flex" justifyContent="space-between" width="100%" mb="20px">
          <Typography variant="h5" color="primary" style={{ fontWeight: 800 }}>
            予約一覧
          </Typography>
        </Box>
        <DataTable
          data={p.customerData}
          columns={p.columns}
          isTableLogoSetting={true} // since it has minWidth: 350
          showDeleteAndCheckBoxIcon={false}
          showPagination={false}
          showTablePagination={false}
          tableHeaderStyle={{ backgroundColor: '#E0E0E0' }}
          nonDataText="予約データがありません。"
        />
        <PillButton
          className={classes.pillBtn}
          variant="contained"
          type="button"
        >
          お客様情報に戻る
        </PillButton>
      </Box>
    </div>
  );
};

export default MypageReservationListDesktop;
