export const types = {
  SET_ORGNAME: 'ui/orgname/SET' as const,
};

export const setOrgName = (payload:string) => ({
  type: types.SET_ORGNAME,
  payload,
});

export type Actions =
(
    | ReturnType<typeof setOrgName>
)
