import {
  Box, Button, Dialog, DialogActions, DialogTitle,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Props } from './type';


export default function DialogConfirmLogout({ handleConfirmLogout, isOpen = false, data = '' }:Props) {
  const handleActionConfirm = () => {
    handleConfirmLogout?.();
  };

  useEffect(() => {
    /**
       * Check authority
       */
  }, []);

  return (
    <Box>
      <Dialog
        open={isOpen}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">セッションが切れましたので、ログイン画面に戻ります。</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleActionConfirm}
            color="primary"
            autoFocus={true}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
