import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    // mobile: true;

  }
}

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#D0111B',
      light: '#D8E3C0',
    },
    secondary: {
      main: '#e91e63',
      light: '#f9e2e4',
    },
    grey: {
      900: '#989898',
      800: '#4B4B4B',
      700: '#606060',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    subtitle2: {
      fontSize: '12px',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        wordBreak: 'break-word',
      },
    },
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        color: '#606060',
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: 'black',
        },
      },
    },
    MuiMenuItem: {
      root: {
        maxWidth: '400px',
        textOverflow: 'ellipsis',
        display: 'block',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // mobile: 400,
    },
  },
});

export default globalTheme;
