import { IStaffMasterDetail } from 'types/staffMasterType';
import ApiClient from './ApiClient';
import {
 LoginDataType, StaffDetailType, LoginDataMCustomerType, SettingLoginDataType,
} from '../types/authType';

export const loginApi = async (companyCode: string, params: { loginCode: string, password: string }): Promise<LoginDataType> => {
  const { data } = await ApiClient.postJsonData(`/users/login/${companyCode}`, {}, params);
  return data;
};

export const validateAccessTokenApi = async (companyCode: string, staffId: string): Promise<IStaffMasterDetail> => {
  const { data } = await ApiClient.get(`/v1/staffs/${companyCode}`, { staffId });
  return data;
};

/**
 * Staff info & roleResponses
 */
export const getStaffDetailApi = async (companyCode: string, staffId: string): Promise<StaffDetailType> => {
  const { data } = await ApiClient.get(`/v1/home/${companyCode}/${staffId}`, {});
  return data;
};

// Customer
export const loginCustomer = async (companyCode: string, params: { email: string, password: string }): Promise<LoginDataMCustomerType> => {
  const { data } = await ApiClient.postJsonData(`/customer/login/${companyCode}`, {}, params);
  return data;
};

export const accessHistoryApi = async (params: { accessUrl:string, companyCode:string, customerBasicDataId?:string, }): Promise<any> => {
  const { data } = await ApiClient.postJsonData('/v1/access/history', {}, params);
  return data;
};

export const getSettingLogin = async (companyCode:string):Promise<SettingLoginDataType> => {
  const { data } = await ApiClient.get(`/v1/companies/${companyCode}`, {});
  return data;
};

export default validateAccessTokenApi;
