import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { accessHistoryApi, getSettingLogin } from 'api/authApi';
import { getRegisterApi } from 'api/registerCustomerApi';
import PillButton from 'components/atoms/Buttons/PillButton';
import { COMPANY_CODE_POST_MESSAGE, DOMAIN_POST_MESSAGE } from 'constants/configPostMessage';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

const MypageRegistration: React.FC = () => {
	const companyCode = window.location.pathname.split('/')[1];
	const [companyName, setCompanyName] = useState<string>('');
	const [isSubmit, setSubmit] = useState(false);
	const history = useHistory();
	const ref = useRef<any>(null);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const timeOutRef = useRef<any>(null);

	const submit = async (e: React.FormEvent) => {
		e.preventDefault();
		localStorage.clear();
		sessionStorage.clear();
		const form = ref.current.elements;
		const params = {
			mailAddress: form.email.value,
		};
		try {
			setSubmit(true);
			const response = await getRegisterApi(companyCode, params);
			history.push(`/${companyCode}/mypage/setNewPassword/${response.customer}/register`);
		} catch (error) {
			if (error?.response?.status === 500) {
				enqueueSnackbar('Server error', { variant: 'error' });
				return;
			}
			if (!error?.response) return;
			const errorResponse = error?.response?.data?.errors || error?.response?.data;
			if (errorResponse?.length && Array.isArray(errorResponse)) {
				errorResponse.forEach((err: any) => {
				enqueueSnackbar(err.defaultMessage ? err.defaultMessage : err.error, { variant: 'error' });
				});
			} else {
				enqueueSnackbar(error?.response?.data?.defaultMessage || error?.response?.error || error?.response?.data?.message || String(error?.response?.data || 'サーバー側でエラーが発生しました。'), { variant: 'error' });
			}
		} finally {
			setSubmit(false);
		}
	};

	const fetchData = async () => {
		const res = await getSettingLogin(companyCode);
		setCompanyName(res?.companyName || '');
		return res;
	};

	const updateSize = () => {
		if (timeOutRef.current) {
		clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = setTimeout(() => {
		const height = document.getElementsByTagName('body')[0]?.clientHeight || 0;
		const obj = { event: 'iframe_height', height };
		// 親へiframeの高さを送信
		// postMessage(<送信する値>, <送信先のドメイン>)
		window.parent.postMessage(obj, DOMAIN_POST_MESSAGE);
		}, 500);
	};

	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyCode]);

	useEffect(() => {
		// Save history access screen
		const paramsPost = { accessUrl: `${history?.location?.pathname + history?.location?.search}`, companyCode, customerBasicDataId: '' };
		accessHistoryApi(paramsPost);
		if (companyCode === COMPANY_CODE_POST_MESSAGE) {
			updateSize && updateSize();
			window.addEventListener('resize', updateSize);
		}
		return () => {
			window.removeEventListener('resize', updateSize);
			clearTimeout(timeOutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<div className={classes.root}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				maxWidth="500px"
				width="90%"
			>
				<Box
					className={classes.image}
					alignItems="center"
					display="flex"
					flexDirection="column"
				>
					<img
						src={`/img/logo/logo_${companyCode}.png`}
						alt=""
						style={{ maxHeight: '60px' }}
					/>
					<Typography><strong>{companyName}</strong></Typography>
				</Box>
				<form ref={ref} className={classes.form} noValidate={true} onSubmit={submit}>
					<TextField
						variant="outlined"
						margin="normal"
						// required={true}
						fullWidth={true}
						id="email"
						label="メールアドレス"
						name="email"
						autoComplete="email"
						autoFocus={true}
					/>
					<Typography variant="subtitle2" className={classes.typography1}>
					店舗で登録したメールアドレスを入力し、「登録する」を押してください
					</Typography>
					<Typography variant="subtitle2" className={classes.typography2}>
					登録したメールアドレスをお忘れまたは変更の場合は、 店舗にご連絡ください
					</Typography>
					<Box className={classes.submit}>
						<PillButton
							className={classes.pillBtn}
							variant="contained"
							color="primary"
							disabled={isSubmit}
							type="submit"
						>
							登録する
						</PillButton>
					</Box>
				</form>
			</Box>
		</div>

	);
};

export default MypageRegistration;
