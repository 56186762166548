import { getStaffDetailApi, loginApi } from 'api/authApi';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StaffDetailType } from 'types/authType';
import {
  removeLocalStorage, removeSessionStorage, setLocalStorage, setSessionStorage,
} from 'utility/browserStorageUtil';
import {
  setIsAuth, setLoading, setStaffDetail,
} from '../redux/auth/actions';

const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyCode } = useParams<{ companyCode: string }>();

  const signin = async (loginCode: string, password: string) => {
    const params = {
      loginCode,
      password,
    };
    try {
      // reset to default
      dispatch(setIsAuth(false));
      dispatch(setLoading(true));

      // BEGIN::Setting to refresh token
      const {
        accessToken, refreshToken, expiresIn, staffId,
      } = await loginApi(companyCode, params);
      setSessionStorage('companyCode', companyCode);
      setLocalStorage('accessToken', accessToken || '');
      setLocalStorage('staffId', staffId);
      setLocalStorage('user', loginCode); // Math.floor(Number(expiresIn) / 60)
      setLocalStorage('expiredToken', moment().utcOffset(9).add(Math.floor(Number(expiresIn) / 60), 'minutes').format('YYYY-MM-DD HH:mm:ss'));
      setLocalStorage('accessRefreshToken', refreshToken || '');
      // END::Setting to refresh token

      const staffDetail = await getStaffDetailApi(companyCode, staffId);
      // Type 1

      dispatch(setStaffDetail({
        staffDetail,
        roles: {},
      }));
      dispatch(setIsAuth(true));
      return companyCode;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const signup = (email: string, password: string) => {
    //
  };

  const signout = () => {
    dispatch(setStaffDetail({
      staffDetail: {} as StaffDetailType,
      roles: {},
    }));
    dispatch(setIsAuth(false));
    removeSessionStorage('companyCode');
    removeLocalStorage('accessToken');
    removeLocalStorage('staffId');
    removeLocalStorage('user');
    removeLocalStorage('accessRefreshToken');
    removeLocalStorage('expiredToken');
    history.push(`/${companyCode}/login`);
  };

  return {
    signin,
    signup,
    signout,
  };
};

export default useAuth;
