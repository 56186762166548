import Login from 'pages/login';
import MypageLogin from 'pages/mypage';
import MypageRegistration from 'pages/mypage/registration';
import MyPageResetPassword from 'pages/mypage/resetPassword';
import MypageSetNewPassword from 'pages/mypage/setNewPassword';
import MypageChangePassword from 'pages/mypage/changePassword';
import MypageCustomerInfo from 'pages/mypage/customerInfo';
import MypageCustomerInContractList from 'pages/mypage/customerInContractList';
import MypageReservationList from 'pages/mypage/reservationList';
import MypageCustomerMeasurementInfo from 'pages/mypage/measurementInfo';
import { lazy } from 'react';
import { PrivateRouteType } from './type';
/**
 * Login is not required
 * Note: sub-routes is not supported
 */
export const publicRoutes = [
  {
    path: ':companyCode/mypage/login',
    title: '',
    loadComponent: MypageLogin,
  },
  {
    path: ':companyCode/mypage/registration',
    title: '',
    loadComponent: MypageRegistration,
  },
  {
    path: ':companyCode/mypage/resetPassword',
    title: '',
    loadComponent: MyPageResetPassword,
  },
  {
    path: ':companyCode/mypage/setNewPassword/:id/:status',
    title: '',
    loadComponent: MypageSetNewPassword,
  },
  {
    path: '/mypage/customer/contractList',
    title: '',
    loadComponent: MypageCustomerInContractList,
  },
  {
    path: '/mypage/customer/reservationList',
    title: '',
    loadComponent: MypageReservationList,
  },
  {
    path: '/login',
    title: 'Login',
    loadComponent: Login,
  },
];

/**
 * Login required
 */
export const privateRoutes: PrivateRouteType[] = [
  {
    title: '',
    path: '/mypage/customer/info',
    hasAppbar: false,
    loadComponent: lazy(() => import('pages/mypage/customerInfo')),
  },
  {
    title: '',
    path: '/mypage/customer/changePassword',
    hasAppbar: false,
    loadComponent: lazy(() => import('pages/mypage/changePassword')),
  },
  {
    title: '',
    path: '/mypage/customer/measurementInfo',
    hasAppbar: false,
    loadComponent: lazy(() => import('pages/mypage/measurementInfo')),
  },
  {
    path: '/mypage/contact/reserve',
    title: 'ご予約までの流れ',
    hasAppbar: false,
    loadComponent: lazy(() => import('pages/reservationBookingOnlineV2')),
  },
];
