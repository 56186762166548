import getRevision from 'api/revision';
import RevisionDomain from 'domain/revision';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route, RouteProps, Redirect,
} from 'react-router-dom';
import { Store } from 'redux/store';

declare let gRevision: string;
const REVISION_KEY: string = 'EC_APP_REVISION';

/**
  * リビジョン管理用 ローカルストレージ保存
  *
  * @param obj セッション格納オブジェクト
  */
function setLocalStorageRevision(obj: any) {
  localStorage.setItem(REVISION_KEY, JSON.stringify({ ...JSON.parse(localStorage.getItem(REVISION_KEY) || '{}'), ...obj }));
}

/**
 * リビジョン管理用 ローカルストレージ取得
 *
 * @param key
 * @returns key セッションキー
 */
function getLocalStorageRevision(key: string) {
  if (!JSON.parse(localStorage.getItem(REVISION_KEY) || '{}') || !JSON.parse(localStorage.getItem(REVISION_KEY) || '{}')[key]) {
    return false;
  }
  return JSON.parse(localStorage.getItem(REVISION_KEY) || '{}')[key];
}

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuth } = useSelector((state: Store) => state.auth);

  // リビジョンチェック
  getRevision()
    .then((response : any) => {
      const revision = new RevisionDomain(response);

      // 現在のリビジョンと取得したリビジョンが異なる場合、リロード
      if (gRevision !== revision.revision) {
        // 無限リロード対策（更新中フラグで管理する）
        const revisionChangingFl = getLocalStorageRevision('revision_changing_fl');
        setLocalStorageRevision({ revision_changing_fl: true });
        if (!revisionChangingFl) {
          // リビジョン更新
          window.location.reload();
        }
      } else {
        // リビジョンが一致
        // LocalStorageのリビジョン更新フラグをfalse
        setLocalStorageRevision({ revision_changing_fl: false });
      }
    });

  return (
    <Route
      {...rest}
      render={(innerProps) => {
        const match = innerProps.match.params?.companyCode || 'your-company-code';
        const loginPath = `/${match}/mypage/login`;

        return (isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: innerProps.location },
            }}
          />
        ));
      }}
    />
  );
};

export default PrivateRoute;
