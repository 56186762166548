import { makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
    margin: '0 2rem',
    fontWeight: 700,
  },
  fontBoldabc: {
    fontWeight: 700,
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
export default useStyles;
