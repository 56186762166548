import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { getSettingLogin } from 'api/authApi';
import useStyles from './style';

function TopbarDesktop() {
  const [openMenu, setOpenMenu] = useState(false);
  const classes = useStyles();
  const companyCode = window.location.pathname.split('/')[1];
  const [companyName, setCompanyName] = useState<string>('');

  const fetchData = async () => {
    const res = await getSettingLogin(companyCode);
    setCompanyName(res?.companyName || '');
    return res;
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);

  return (
    <AppBar className={classes.root} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <IconButton className={classes.imageButton}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <img
                src={`/img/logo/logo_${companyCode}.png`}
                alt=""
                style={{ maxHeight: '60px' }}
              />
              <Typography><strong>{companyName}</strong></Typography>
            </Box>
          </IconButton>
          <Typography className={classes.text} variant="h5" variantMapping={{ h5: 'h1' }}>
            マイページ
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopbarDesktop;
