import { makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '30px',
    position: 'fixed',
    top: '0px',
    backgroundColor: 'white',
    height: '100%',
    width: '100vw',
    zIndex: 3,
  },
  menuContainer: {
  },
  mainMenuWrapper: {
    marginBottom: '100px',
  },
  firstText: {
    fontWeight: theme.typography.fontWeightLight,
    margin: '50px 0 40px 0',
  },
  text: {
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: '40px',
  },
  closeIconButton: {
    position: 'absolute',
    right: '20px',
  },
  closeIcon: {
    fontSize: '40px',
  },
}));
export default useStyles;
