import {
  removeLocalStorage, removeSessionStorage, setLocalStorage, setSessionStorage,
} from 'utility/browserStorageUtil';
// eslint-disable-next-line import/prefer-default-export
export function stringToBoolean(value: string | null) {
  switch (value) {
    case 'true': case 'yes': case '1': return true;
    case 'false': case 'no': case '0': case null: return false;
    default: return Boolean(value);
  }
}

export function removeToken() {
  removeSessionStorage('companyCode');
  removeLocalStorage('accessToken');
  removeLocalStorage('staffId');
  removeLocalStorage('user');
}

export function returnTitle(title: string, orgName?: string | null): string {
  if (title === '予約表 [ 恵比寿店 ]') {
    const temp = title.split('[');
    if (orgName) {
      temp[1] = orgName;
    }
    return `${temp[0]} [ ${temp[1]} ]`;
  }
  return title;
}

export const formatNumber = (num: string) => {
  const temp = Number(num.replace(/,/g, ''));
  const dotAvailable = num.includes('.');

  // eslint-disable-next-line no-useless-escape
  if (isNaN(temp) && /^[\+\-]?\d*\.?\,?\d+(?:[Ee][\+\-]?\d+)?$/i.test(
    num,
  ) === false) {
    return num;
  }
  const displayDotAvaiAble = () => {
    if (dotAvailable) {
      return `.${num.split('.')[1]}`;
    }
    return '';
  };
  const p = temp.toFixed(2).split('.');
  // eslint-disable-next-line consistent-return
  return `${p[0].split('').reverse().reduce((curr, next, index) => (next + ((index !== 0 && (index % 3 === 0)) ? ',' : '') + curr), '')}${displayDotAvaiAble()}`;
};

export default function numberWithCommas(x: number) {
  return x.toLocaleString();
}


export function getTotalValue<T>(key: keyof T, data: T[]) {
  return data.reduce((total, item) => {
    const { [key]: target } = item;
    if (typeof target !== 'number') {
      return 0;
    }
    return total + target;
  }, 0);
}
