import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PillButton from 'components/atoms/Buttons/PillButton';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
// import validateAccessTokenApi from 'api/validateAccessTokenApi';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

const Copyright = () => (
  <Typography variant="subtitle2" color="textSecondary" align="center">
    ID/パスワードを忘れたときは社内システム管理者にご連絡ください。
  </Typography>
);

const Login: React.FC = () => {
  const [isSubmit, setSubmit] = useState(false);
  const history = useHistory();
  const { signin } = useAuth();
  const classes = useStyles();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const searchParams = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = ref.current.elements;
    const id = form.ID.value;
    const password = form.password.value;

    // validate inputs
    if (!id || !password) {
      enqueueSnackbar('IDまたはパスワードが異なります', { variant: 'error' });
      return;
    }

    try {
      setSubmit(true);
      const companyCode = await signin(id, password);

      // redirect
      const path = `/${companyCode}`;
      redirectDashboard(path);
    } catch (error) {
      if (error?.response?.status === 500) {
        enqueueSnackbar('Server error', { variant: 'error' });
        return;
      }
      // BE return 2 diff type of responses, zzz
      if (!error?.response) return;
      const errorResponse = error?.response?.data?.errors || error?.response?.data;
      if (errorResponse?.length && Array.isArray(errorResponse)) {
        errorResponse.forEach((err: any) => {
          enqueueSnackbar(err.defaultMessage ? err.defaultMessage : err.error, { variant: 'error' });
        });
      } else {
        enqueueSnackbar(error?.response?.data?.defaultMessage || error?.response?.error || error?.response?.data?.message || String(error?.response?.data || 'サーバー側でエラーが発生しました。'), { variant: 'error' });
      }
    } finally {
      setSubmit(false);
    }
  };

  const redirectDashboard = (path: string = '/') => {
    const { state }: any = history.location;
    history.push(state?.from?.pathname || path);
  };

  // const processUrlToken = async (accessToken: string) => {
  //   try {
  //     setLocalStorage('accessToken', accessToken);
  //     const staffId = getLocalStorage('staffId');
  //     const companyCode = getSessionStorage('companyCode');
  //     const path = `/${companyCode}`;
  //     await validateAccessTokenApi(companyCode, staffId);
  //     dispatch(setIsAuth(true));
  //     redirectDashboard(path);
  //   } catch (error) {
  //     removeLocalStorage('accessToken');
  //     throw error;
  //   }
  // };

  // useEffect(() => {
  //   // const accessToken = searchParams.get('accessToken');
  //   const accessToken = getLocalStorage('accessToken');
  //   if (accessToken) {
  //     processUrlToken(accessToken);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h2" className={classes.title}>
          Win-Board
        </Typography>
        <form ref={ref} className={classes.form} noValidate={true} onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            id="id"
            label="ID"
            name="ID"
            autoComplete="ID"
            autoFocus={true}
          />
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Box className={classes.submit}>
            <PillButton
              variant="contained"
              color="primary"
              disabled={isSubmit}
              type="submit"
            >
              ログインする
            </PillButton>
          </Box>
        </form>
        <Box mt={2}>
          <Copyright />
        </Box>
      </div>
    </Container>
  );
};
export default Login;
