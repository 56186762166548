import { TableCell, useMediaQuery, useTheme } from '@material-ui/core';
import { accessHistoryApi } from 'api/authApi';
import { DataTableColumn } from 'components/organismos/DataTable/interface';
import { COMPANY_CODE_POST_MESSAGE, DOMAIN_POST_MESSAGE } from 'constants/configPostMessage';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import MypageReservationListDesktop from './desktop';
import MypageReservationListMobile from './mobile';
import useStyles from './style';

export interface CustomerReservation {
  reservationDateTime: string
  storeName: string
}

const verticalLineStyle = {
  borderLeft: '1px solid rgba(0, 0, 0, 0.14)',
};

const MypageReservationList: React.FC = () => {
  const companyCode = window.location.pathname.split('/')[1];
	const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const timeOutRef = useRef<any>(null);

  const updateSize = () => {
		if (timeOutRef.current) {
		clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = setTimeout(() => {
		const height = document.getElementsByTagName('body')[0]?.clientHeight || 0;
		const obj = { event: 'iframe_height', height };
		// 親へiframeの高さを送信
		// postMessage(<送信する値>, <送信先のドメイン>)
		window.parent.postMessage(obj, DOMAIN_POST_MESSAGE);
		}, 500);
	};

  useEffect(() => {
		// Save history access screen
		const paramsPost = { accessUrl: `${history?.location?.pathname + history?.location?.search}`, companyCode, customerBasicDataId: '' };
		accessHistoryApi(paramsPost);
    if (companyCode === COMPANY_CODE_POST_MESSAGE) {
			updateSize && updateSize();
			window.addEventListener('resize', updateSize);
		}
		return () => {
			window.removeEventListener('resize', updateSize);
			clearTimeout(timeOutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const customerData: CustomerReservation[] = [{
    reservationDateTime: '2021年10月28日16時〜',
    storeName: '恵比寿店',
  }];

  const columns: DataTableColumn[] = [
    {
      id: 'contractDate',
      label: '予約日時',
      align: 'center',
      width: '65%',
      content(row: CustomerReservation, index) {
        return (
          <TableCell
            key={index}
            align="center"
          >
            {row.reservationDateTime}
          </TableCell>
        );
      },
    },
    {
      id: 'courseName',
      label: '店舗',
      align: 'center',
      width: '35%',
      style: verticalLineStyle,
      content(row: CustomerReservation, index) {
        return (
          <TableCell
            key={index}
            align="center"
            style={verticalLineStyle}
          >
            {row.storeName}
          </TableCell>
        );
      },
    },
  ];

  if (isDesktop) {
    return (
      <div className={classes.rootDesktop}>
        <MypageReservationListDesktop
          customerData={customerData}
          columns={columns}
        />
      </div>
    );
  }
  return (
    <div className={classes.rootMobile}>
      <MypageReservationListMobile
        customerData={customerData}
        columns={columns}
      />
    </div>
  );
};

export default MypageReservationList;
