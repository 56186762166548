import Box from '@material-ui/core/Box';
import DataTable from 'components/organismos/DataTable';
import { DataTableColumn } from 'components/organismos/DataTable/interface';
import Topbar from 'components/organismos/Mypage/Topbar';
import React, { useState } from 'react';
import useStyles from './style';

export interface CustomerContract {
  contractDate: string
  courseName: string
  numberOfTimes: string
  expiryDate: string
}

export interface CustomerContractPropsList {
  customerContracts: CustomerContract[]
  columns: DataTableColumn[]
}

const MypageCustomerInContractListMobile: React.FC<CustomerContractPropsList> = (p: CustomerContractPropsList) => {
  const [isSubmit, setSubmit] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="600px"
        width="90%"
      >
        <Topbar title="契約中一覧" />
        <DataTable
          data={p.customerContracts}
          columns={p.columns}
          isTableLogoSetting={true} // since it has minWidth: 350
          showDeleteAndCheckBoxIcon={false}
          showPagination={false}
          showTablePagination={false}
          tableHeaderStyle={{ backgroundColor: '#E0E0E0' }}
          nonDataText="契約中データがありません。"
        />
      </Box>
    </div>
  );
};

export default MypageCustomerInContractListMobile;
