import { AuthState, StaffDetailType } from 'types/authType';
import { Actions, types } from './actions';

const initialState: AuthState = {
  isAuth: false,
  error: [],
  isLoading: false,
  staffDetail: {} as StaffDetailType,
  roles: {
    orgAccess: undefined,
    exeAccess: undefined,
    staffAccess: undefined,
    roleAccess: undefined,
    employAccess: undefined,
    customerAccess: undefined,
    customerMedicalRecord: undefined,
    customerContractHistory: undefined,
    customerAccountHistory: undefined,
    customerProductHistory: undefined,
    customerTicketHistory: undefined,
    customerReservationHistory: undefined,
    customerResponseInfo: undefined,
    customerManagerSetting: undefined,
    reservationManagement: undefined,
    reservationStoreSetting: undefined,
    commonSalesMenuSection: undefined,
    commonSalesMenu: undefined,
    commonContractMenuClass: undefined,
    commonContractMenu: undefined,
    commonContractCourseClass: undefined,
    commonContractCourseCategory: undefined,
    commonContractCourse: undefined,
    commonRegisterMedium: undefined,
    commonRegisterMediumSubClass: undefined,
    commonRegisterRate: undefined,
    commonRegisterDiscount: undefined,
  },
};

const reducer = (state = initialState, action: Actions): AuthState => {
  const newState = { ...state };
  switch (action.type) {
    case types.SET_LOADING:
      newState.isLoading = action.payload;
      return newState;

    case types.SET_IS_AUTH:
      newState.isAuth = action.payload;
      return newState;

    case types.LOGIN_ERROR:
      newState.error = action.payload;
      return newState;

    case types.SET_STAFF_DETAIL:
      newState.staffDetail = action.payload.staffDetail;
      newState.roles = action.payload.roles;
      newState.error = action.payload.error || [];
      return newState;

    default:
      return state;
  }
};

export default reducer;
