import { makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '50px',
  },
  pointBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  pointTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    height: '50px',
  },
  pointData: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  pillBtn: {
    width: '100%',
    height: '50px',
  },
  typography: {
    width: '350px',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default useStyles;
