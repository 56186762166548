import React, {
  useCallback, useEffect, useRef, useState,
 } from 'react';
 import {
   BrowserRouter as Router, Switch, Route, Redirect,
 } from 'react-router-dom';
 import moment from 'moment';
 import useValidateToken from 'hooks/useValidateToken';
 import { useSelector } from 'react-redux';
 import { Store } from 'redux/store';
 import {
   LinearProgress, Box, CircularProgress,
 } from '@material-ui/core';
 import Page404 from 'pages/error/Page404';
 import Page403 from 'pages/error/Page403';
 import DialogConfirmLogout from 'components/organismos/DialogConfirmLogout';
 import { getSessionStorage, removeLocalStorage } from 'utility/browserStorageUtil';
 import { renderPrivateRoutes, renderPublicRoutes } from './router/routeElements';

 // momentの日本語設定
 moment.locale('ja', {
   months: ['１月', '2月', '3月', '4月', '5月', '６月', '７月', '８月', '９月', '１０月', '１１月', '１２月'],
   weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
   weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
 });

 const App: React.FC = () => {
   const { isLoading } = useSelector((state: Store) => state.auth);
   const { isTokenChecked } = useValidateToken();
   const [isOpenConfirmLogout, setIsOpenConfirmLogout] = useState<boolean>(false);
   const timeRef = useRef<number>(1);
   const timeOutRef = useRef<any>(null);

   const handleConfirmLogout = () => {
     setIsOpenConfirmLogout(false);
     timeRef.current = 1;
     const companyCode = getSessionStorage('companyCode');
     window.location.href = companyCode ? `/${companyCode}/mypage/login` : window.location.pathname;
   };

   const handleMouseDown = useCallback(
     () => {
       if (timeRef.current <= 0) {
          setIsOpenConfirmLogout(true);
       }
       if (timeOutRef.current) {
         clearTimeout(timeOutRef.current);
       }
       timeOutRef.current = setTimeout(() => {
         timeRef.current = 0;
         removeLocalStorage('accessToken');
         removeLocalStorage('accessRefreshToken');
         removeLocalStorage('expiredToken');
       }, 900000); // 15 minutes = 900 000 milliseconds
     },
     // eslint-disable-next-line react-hooks/exhaustive-deps
     [],
   );

   const handleClearMouseDown = () => {
     if (timeOutRef.current) {
       clearTimeout(timeOutRef.current);
     }
   };

   useEffect(() => {
     // Check is page not page login
     window.addEventListener('mousedown', handleMouseDown);
     // cleanup this component
     return () => {
       window.removeEventListener('mousedown', handleClearMouseDown);
     };
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // prevent router redirect by return plain html
   if (!isTokenChecked) {
     return (
       <div style={{
         height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center',
       }}
       >
         <CircularProgress size={30} />
       </div>
     );
   }

   return (
     <>
       {isLoading && (
       <Box position="absolute" width="100%">
         <LinearProgress />
       </Box>
       )}

       <Router>
         <Switch>
           <Route
             path="/:url*(/+)"
             exact={true}
             strict={true}
             render={({ location }) => (
               <Redirect to={location.pathname.replace(/\/+$/, '')} />
             )}
           />

           <Route path="/:companyCode/403">
             <Page403 />
           </Route>

           <Route path="/:companyCode/404">
             <Page404 />
           </Route>

           {renderPublicRoutes()}
           {renderPrivateRoutes()}
         </Switch>
       </Router>
       <DialogConfirmLogout handleConfirmLogout={handleConfirmLogout} isOpen={isOpenConfirmLogout} />
     </>
   );
 };

 export default App;

