import { Box, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { getSettingLogin } from 'api/authApi';
import clsx from 'clsx';
import PillButton from 'components/atoms/Buttons/PillButton';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'redux/store';
import SidebarItem from './SidebarItem';
import useStyles from './styles';
import { SidebarMenu } from './type';

export default React.memo(() => {
  const classes = useStyles();
  const companyCode = window.location.pathname.split('/')[1];
  const [companyName, setCompanyName] = useState<string>('');
  const mediumSizeIcon = {
    width: '36px',
    height: '36px',
  };

  const { signout } = useAuth();
  const {
    staffDetail,
    roles: {
      customerAccess, contractStatus,
    },
  } = useSelector((reduxState: Store) => reduxState.auth);
  const matches = window.innerWidth > 1024; // Ipad pro and below
  const [drawerState, setDrawerState] = useState(matches);

  const handleCloseOpenClick = () => {
    setDrawerState(!drawerState);
  };

  const fetchData = async () => {
    const res = await getSettingLogin(companyCode);
    setCompanyName(res?.companyName || '');
    return res;
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);


  const sidebarItems: SidebarMenu[] = [
    {
      title: 'ホーム',
      accessible: true,
      link: '/',
    },
    {
      title: '顧客管理',
      accessible: !!customerAccess && !customerAccess?.isDisabled,
      items: [
        {
          title: '顧客情報一覧',
          link: '/customer',
          accessible: !!customerAccess && !customerAccess?.isDisabled,
        },
      ],
    },
    {
      title: '売上管理',
      accessible: true,
    },
    {
      title: '契約管理',
      accessible: !!contractStatus,
      items: [
        {
          title: '契約状況',
          link: '/contractStatus',
          accessible: !!contractStatus,
        },
      ],
    },
    {
      title: 'ヘルプページ',
      accessible: true,
      anchor: 'https://win-board-help.zendesk.com/hc/ja',
    },
  ];



  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerState,
        [classes.drawerClose]: !drawerState,
      })}
      classes={{
        root: classes.drawer,
        paper: clsx({
          [classes.drawerPaper]: true,
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        }),
      }}
    >
      <Box className={classes.toolbar}>
        <Box>
          <Box className={classes.logo}>
            <Box
              style={{
                visibility: drawerState ? 'visible' : 'hidden',
                minWidth: '150px',
                minHeight: '1px',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              <img
                src={`/img/logo/logo_${companyCode}.png`}
                alt=""
                style={{ maxHeight: '60px' }}
              />
              <Typography style={{ color: '#D93E46', textAlign: 'center', margin: '0.1rem' }}><strong>{companyName}</strong></Typography>
            </Box>
            <IconButton
              className={clsx(classes.iconButton, {
                [classes.iconButtonOpen]: drawerState,
                [classes.iconButtonClose]: !drawerState,
              })}
              onClick={handleCloseOpenClick}
            >
              {drawerState ? <CloseIcon style={mediumSizeIcon} /> : <MenuIcon style={mediumSizeIcon} />}
            </IconButton>
          </Box>

          <List className={classes.list}>
            {sidebarItems.filter((item) => item.accessible && drawerState).map((menuItem, i) => (
              <SidebarItem
                key={menuItem.title + String(i)}
                menuItem={menuItem}
              />
            ))}
          </List>
        </Box>

        <Box margin="1rem auto">
          <PillButton
            variant="contained"
            color="default"
            className={classes.logoutButton}
            style={{ display: drawerState ? '' : 'none' }}
            onClick={signout}
          >
            <strong>ログアウトする</strong>
          </PillButton>
        </Box>
      </Box>
    </Drawer>
  );
});
