import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { accessHistoryApi, getSettingLogin } from 'api/authApi';
import { postRegisterApi } from 'api/registerCustomerApi';
import PillButton from 'components/atoms/Buttons/PillButton';
import { COMPANY_CODE_POST_MESSAGE, DOMAIN_POST_MESSAGE } from 'constants/configPostMessage';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

const MypageSetNewPassword: React.FC = () => {
  const companyCode = window.location.pathname.split('/')[1];
  const [companyName, setCompanyName] = useState<string>('');
  const id = window.location.pathname.split('/')[4];
  const status = window.location.pathname.split('/')[5];
  const history = useHistory();
  const [isSubmit, setSubmit] = useState(false);
  const ref = useRef<any>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
	const timeOutRef = useRef<any>(null);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
		const form = ref.current.elements;
		const params = {
			confirmCode: form.validationCode.value,
      password: form.password.value,
      confirmPassword: form.confirmPassword.value,
      customerBasicDataId: id,
		};
		try {
      setSubmit(true);
			const response = await postRegisterApi(companyCode, params);
      enqueueSnackbar(status === 'register' ? '登録出来ました。' : 'パスワードを変更しました。', { variant: 'success' });
			history.push(`/${companyCode}/mypage/login`);
		} catch (error) {
			if (error?.response?.status === 500) {
				enqueueSnackbar('Server error', { variant: 'error' });
				return;
			}
			if (!error?.response) return;
			const errorResponse = error?.response?.data?.errors || error?.response?.data;
			if (errorResponse?.length && Array.isArray(errorResponse)) {
				errorResponse.forEach((err: any) => {
				enqueueSnackbar(err.defaultMessage ? err.defaultMessage : err.error, { variant: 'error' });
				});
			} else {
				enqueueSnackbar(error?.response?.data?.defaultMessage || error?.response?.error || error?.response?.data?.message || String(error?.response?.data || 'サーバー側でエラーが発生しました。'), { variant: 'error' });
			}
		} finally {
      setSubmit(false);
    }
  };

  const fetchData = async () => {
		const res = await getSettingLogin(companyCode);
		setCompanyName(res?.companyName || '');
		return res;
	};

  const updateSize = () => {
		if (timeOutRef.current) {
		clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = setTimeout(() => {
		const height = document.getElementsByTagName('body')[0]?.clientHeight || 0;
		const obj = { event: 'iframe_height', height };
		// 親へiframeの高さを送信
		// postMessage(<送信する値>, <送信先のドメイン>)
		window.parent.postMessage(obj, DOMAIN_POST_MESSAGE);
		}, 500);
	};

	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyCode]);

	useEffect(() => {
		// Save history access screen
		const paramsPost = { accessUrl: `${history?.location?.pathname + history?.location?.search}`, companyCode, customerBasicDataId: '' };
		accessHistoryApi(paramsPost);
    if (companyCode === COMPANY_CODE_POST_MESSAGE) {
			updateSize && updateSize();
			window.addEventListener('resize', updateSize);
		}
		return () => {
			window.removeEventListener('resize', updateSize);
			clearTimeout(timeOutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="500px"
        width="90%"
      >
        <Box
					className={classes.image}
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
					<img
						src={`/img/logo/logo_${companyCode}.png`}
						alt=""
            style={{ maxHeight: '60px' }}
					/>
					<Typography><strong>{companyName}</strong></Typography>
        </Box>
        <form ref={ref} className={classes.form} noValidate={true} onSubmit={submit}>
          <Box display="flex" justifyContent="center">
            <Typography variant="subtitle2" className={classes.checkEmailText}>
              ご登録メールアドレスに確認メールを送付しました。
              記載の「確認コード」をご確認ください。
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            id="validationCode"
            label="確認コード"
            name="validationCode"
          />
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            name="confirmPassword"
            label="パスワード（確認用）"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
          />
          <Typography variant="subtitle2" className={classes.passwordInfoText}>
            ※パスワードは半角英数混在8文字以上で入力をお願いします
          </Typography>
          <Box className={classes.submit}>
            <PillButton
              className={classes.pillBtn}
              variant="contained"
              color="primary"
              disabled={isSubmit}
              type="submit"
            >
              パスワードを設定する
            </PillButton>
          </Box>
        </form>
      </Box>
    </div>

  );
};

export default MypageSetNewPassword;
