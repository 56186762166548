import React from 'react';
import PillButton from 'components/atoms/Buttons/PillButton';
import './style.css';
import { useHistory } from 'react-router-dom';

const Page403: React.FC = () => {
  const history = useHistory();
  return (
    <div className="container">
      <h1>403</h1>
      <p>access not granted</p>
      <p>アクセス権がありません。</p>
      {/* <PillButton variant="contained" color="primary">Go home</PillButton> */}
    </div>
  );
};

export default Page403;
