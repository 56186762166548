import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PillButton from 'components/atoms/Buttons/PillButton';
import DataTable from 'components/organismos/DataTable';
import { DataTableColumn } from 'components/organismos/DataTable/interface';
import TopbarDesktop from 'components/organismos/Mypage/Topbar/desktop';
import React, { useState } from 'react';
import useStyles from './style';

export interface CustomerContract {
  contractDate: string
  courseName: string
  numberOfTimes: string
  expiryDate: string
}

export interface CustomerContractPropsList {
  customerContracts: CustomerContract[]
  columns: DataTableColumn[]
}

const MypageCustomerInContractListDesktop: React.FC<CustomerContractPropsList> = (p: CustomerContractPropsList) => {
  const [isSubmit, setSubmit] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="600px"
        width="90%"
      >
        <TopbarDesktop />
        <Box display="flex" justifyContent="space-between" width="100%" mb="20px">
          <Typography variant="h5" color="primary" style={{ fontWeight: 800 }}>
            契約中一覧
          </Typography>
        </Box>
        <DataTable
          data={p.customerContracts}
          columns={p.columns}
          isTableLogoSetting={true} // since it has minWidth: 350
          showDeleteAndCheckBoxIcon={false}
          showPagination={false}
          showTablePagination={false}
          tableHeaderStyle={{ backgroundColor: '#E0E0E0' }}
          nonDataText="契約中データがありません。"
        />
      </Box>
      <PillButton
        className={classes.pillBtn}
        variant="contained"
        type="button"
      >
        お客様情報に戻る
      </PillButton>
    </div>
  );
};

export default MypageCustomerInContractListDesktop;
