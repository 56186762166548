import { makeStyles, Theme } from '@material-ui/core';

const etcButtonSize = '300px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    minWidth: '150px',
		minHeight: '1px',
		marginBottom: '30px',
		'& p': {
			color: '#D93E46',
			textAlign: 'center',
			margin: '0.1rem',
		},
  },
  pillBtn: {
    width: '100%',
    height: '50px',
  },
  registerBtn: {
    backgroundColor: '#F9E2E4',
    width: etcButtonSize,
    height: '30px',
    margin: '10px 0',
  },
  checkEmailText: {
    textAlign: 'center',
    marginTop: '20px',
    width: '300px',
  },
  passwordInfoText: {
    textAlign: 'left',
    marginTop: '20px',
  },
  forgotPasswordBtn: {
    backgroundColor: '#606060',
    width: etcButtonSize,
    height: '30px',
    margin: '10px 0',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default useStyles;
