import { makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		maxWidth: '100%',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	pillBtn: {
		width: '100%',
		height: '50px',
	},
	typography1: {
		width: '100%',
		textAlign: 'center',
		margin: '0 auto',
		padding: '20px 0',
	},
	typography2: {
		width: '315px',
		textAlign: 'center',
		margin: '0 auto',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		textAlign: 'center',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	image: {
		minWidth: '150px',
		minHeight: '1px',
		marginBottom: '30px',
		'& p': {
			color: '#D93E46',
			textAlign: 'center',
			margin: '0.1rem',
		},
	},
}));
export default useStyles;
