import { TableCell, useMediaQuery, useTheme } from '@material-ui/core';
import { accessHistoryApi } from 'api/authApi';
import { DataTableColumn } from 'components/organismos/DataTable/interface';
import { COMPANY_CODE_POST_MESSAGE, DOMAIN_POST_MESSAGE } from 'constants/configPostMessage';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import MypageCustomerInContractListDesktop from './desktop';
import MypageCustomerInContractListMobile from './mobile';
import useStyles from './style';

export interface CustomerContract {
  contractDate: string
  courseName: string
  numberOfTimes: string
  expiryDate: string
}

const verticalLineStyle = {
  borderLeft: '1px solid rgba(0, 0, 0, 0.14)',
};

const MypageCustomerInContractList: React.FC = () => {
  const companyCode = window.location.pathname.split('/')[1];
	const history = useHistory();
  const [isSubmit, setSubmit] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const timeOutRef = useRef<any>(null);

  const updateSize = () => {
		if (timeOutRef.current) {
		clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = setTimeout(() => {
		const height = document.getElementsByTagName('body')[0]?.clientHeight || 0;
		const obj = { event: 'iframe_height', height };
		// 親へiframeの高さを送信
		// postMessage(<送信する値>, <送信先のドメイン>)
		window.parent.postMessage(obj, DOMAIN_POST_MESSAGE);
		}, 500);
	};

  useEffect(() => {
		// Save history access screen
		const paramsPost = { accessUrl: `${history?.location?.pathname + history?.location?.search}`, companyCode, customerBasicDataId: '' };
		accessHistoryApi(paramsPost);
    if (companyCode === COMPANY_CODE_POST_MESSAGE) {
			updateSize && updateSize();
			window.addEventListener('resize', updateSize);
		}
		return () => {
			window.removeEventListener('resize', updateSize);
			clearTimeout(timeOutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const customerData: CustomerContract[] = [{
    contractDate: '2021年1月30日',
    courseName: 'ラジオ波フェイス30',
    numberOfTimes: '3/4',
    expiryDate: '2022年1月29日',
  },
  {
    contractDate: '2021年1月30日',
    courseName: 'ラジオ波フェイス30',
    numberOfTimes: '1/4',
    expiryDate: '2022年1月29日',
  },
  {
    contractDate: '2021年1月30日',
    courseName: 'ラジオ波フェイス30',
    numberOfTimes: '2/4',
    expiryDate: '2022年1月29日',
  }];

  const columns: DataTableColumn[] = [
    {
      id: 'contractDate',
      label: '契約日',
      align: 'center',
      width: '25%',
      content(row: CustomerContract, index) {
        return (
          <TableCell
            key={index}
            align="center"
          >
            {row.contractDate}
          </TableCell>
        );
      },
    },
    {
      id: 'courseName',
      label: 'コース名',
      align: 'center',
      width: '32%',
      style: verticalLineStyle,
      content(row: CustomerContract, index) {
        return (
          <TableCell
            key={index}
            align="center"
            style={verticalLineStyle}
          >
            {row.courseName}
          </TableCell>
        );
      },
    },
    {
      id: 'numberOfTimes',
      label: '回数',
      align: 'center',
      width: '18%',
      style: verticalLineStyle,
      content(row: CustomerContract, index) {
        return (
          <TableCell
            key={index}
            align="center"
            style={verticalLineStyle}
          >
            {row.numberOfTimes}
          </TableCell>
        );
      },
    },
    {
      id: 'expiryDate',
      label: '有効期限',
      align: 'center',
      width: '25%',
      style: verticalLineStyle,
      content(row: CustomerContract, index) {
        return (
          <TableCell
            key={index}
            align="center"
            style={verticalLineStyle}
          >
            {row.expiryDate}
          </TableCell>
        );
      },
    },
  ];

  if (isDesktop) {
    return (
      <div className={classes.rootDesktop}>
        <MypageCustomerInContractListDesktop
          customerContracts={customerData}
          columns={columns}
        />
      </div>
    );
  }
  return (
    <div className={classes.rootMobile}>
      <MypageCustomerInContractListMobile
        customerContracts={customerData}
        columns={columns}
      />
    </div>
  );
};

export default MypageCustomerInContractList;
