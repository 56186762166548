import { createStore, combineReducers } from 'redux';
import { AuthState } from 'types/authType';
import ui, { UiState } from './ui';
import auth from './auth';

export interface Store {
    ui: UiState;
    auth: AuthState;
}

export default createStore(combineReducers<Store>({ ui, auth }));
