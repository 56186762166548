import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store } from 'redux/store';
import { returnTitle } from 'constants/helper';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { getSessionStorage } from 'utility/browserStorageUtil';
import useStyles from './styles';

export const POS_TITLE = [{ text: 'レジ', value: '0' },
  { text: '返品', value: '1' },
  { text: '両替', value: '2' },
  { text: '精算', value: '3' },
  { text: '閉店処理', value: '4' }];

type AppBarType = {
  title: string
}
function Appbar({ title }: AppBarType) {
  const companyCode = getSessionStorage('companyCode');
  const URLSearchParams = useQuery();
  const tabNow = URLSearchParams.get('tab') || '';
  const history = useHistory();
  const { location: { pathname } } = history;
  const classes = useStyles();
  const {
    orgname,
  } = useSelector((reduxState: Store) => reduxState.ui);
  const titleApp = returnTitle(title, orgname.orgName);// hien thi store theo orgName
  return (
    <AppBar className={classes.root} position="absolute">
      { title !== '' && (
      <Toolbar className={classes.toolbar}>
        <Box>
          <Typography className={classes.text} variant="h5" variantMapping={{ h5: 'h1' }}>
            {(tabNow !== '' && pathname === `/${companyCode}/pos`) ? POS_TITLE[Number(tabNow)]?.text : titleApp}
          </Typography>
        </Box>
      </Toolbar>
      )}
    </AppBar>
  );
}

export default Appbar;
