import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { getSettingLogin } from 'api/authApi';
import PillButton from 'components/atoms/Buttons/PillButton';
import { COMPANY_CODE_POST_MESSAGE, DOMAIN_POST_MESSAGE } from 'constants/configPostMessage';
import useAuthCustomer from 'hooks/useAuthCustomer';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

const MypageLogin: React.FC = () => {
  const companyCode = window.location.pathname.split('/')[1];
  const [companyName, setCompanyName] = useState<string>('');
  const [isSubmit, setSubmit] = useState(false);
  const history = useHistory();
  const { signin } = useAuthCustomer();
  const ref = useRef<any>(null);
	const timeOutRef = useRef<any>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = ref.current.elements;
    const email = form.email.value;
    const password = form.password.value;

    // validate inputs
    if (!email || !password) {
      enqueueSnackbar('IDまたはパスワードが異なります', { variant: 'error' });
      return;
    }

    try {
      setSubmit(true);
      const response = await signin(email, password);
      history.push(`/${companyCode}/mypage/customer/info`);
    } catch (error) {
      if (error?.response?.status === 500) {
        enqueueSnackbar('Server error', { variant: 'error' });
        return;
      }
      if (!error?.response) return;
      const errorResponse = error?.response?.data?.errors || error?.response?.data;
      if (errorResponse?.length && Array.isArray(errorResponse)) {
        errorResponse.forEach((err: any) => {
          enqueueSnackbar(err.defaultMessage ? err.defaultMessage : err.error, { variant: 'error' });
        });
      } else {
        enqueueSnackbar(error?.response?.data?.defaultMessage || error?.response?.error || error?.response?.data?.message || String(error?.response?.data || 'サーバー側でエラーが発生しました。'), { variant: 'error' });
      }
    } finally {
      setSubmit(false);
    }
  };

  const fetchData = async () => {
    const res = await getSettingLogin(companyCode);
    setCompanyName(res?.companyName || '');
    return res;
  };

  const updateSize = () => {
		if (timeOutRef.current) {
		clearTimeout(timeOutRef.current);
		}
		timeOutRef.current = setTimeout(() => {
		const height = document.getElementsByTagName('body')[0]?.clientHeight || 0;
		const obj = { event: 'iframe_height', height };
		// 親へiframeの高さを送信
		// postMessage(<送信する値>, <送信先のドメイン>)
		window.parent.postMessage(obj, DOMAIN_POST_MESSAGE);
		}, 500);
	};

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);

  useEffect(() => {
		if (companyCode === COMPANY_CODE_POST_MESSAGE) {
			updateSize && updateSize();
			window.addEventListener('resize', updateSize);
		}
		return () => {
			window.removeEventListener('resize', updateSize);
			clearTimeout(timeOutRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="500px"
        width="90%"
      >
        <Box
              style={{
                minWidth: '150px',
                minHeight: '1px',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
              }}

        >
              <img
                src={`/img/logo/logo_${companyCode}.png`}
                alt=""
                style={{ maxHeight: '60px' }}
              />
              <Typography style={{ color: '#D93E46', textAlign: 'center', margin: '0.1rem' }}><strong>{companyName}</strong></Typography>
        </Box>
        <form ref={ref} className={classes.form} noValidate={true} onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus={true}
          />
          <TextField
            variant="outlined"
            margin="normal"
            // required={true}
            fullWidth={true}
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Box className={classes.submit}>
            <PillButton
              className={classes.pillBtn}
              variant="contained"
              color="primary"
              disabled={isSubmit}
              type="submit"
            >
              ログイン
            </PillButton>
          </Box>
          <PillButton
            className={classes.registerBtn}
            variant="contained"
            color="default"
            type="button"
            onClick={() => {
              history.push(`/${companyCode}/mypage/registration`);
            }}
          >
            新規登録の方はこちら
          </PillButton>
          <PillButton
            className={classes.forgotPasswordBtn}
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => {
              history.push(`/${companyCode}/mypage/resetPassword`);
            }}
          >
            パスワードを忘れた方はこちら
          </PillButton>
        </form>
        <Typography variant="subtitle2" className={classes.typography}>
          登録したメールアドレスをお忘れまたは変更の場合は、店舗にお問い合わせください。
        </Typography>
      </Box>
    </div>

  );
};

export default MypageLogin;
