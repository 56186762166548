import { combineReducers } from 'redux';

import sidebar, { UiSidebarState } from './sidebar';
import orgname, { IOrgNameState } from './orgName/reducer';

export interface UiState {
  sidebar: UiSidebarState;
  orgname: IOrgNameState;
}

const reducer = combineReducers<UiState>({ sidebar, orgname });

export default reducer;
