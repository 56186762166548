import { accessHistoryApi, loginCustomer } from 'api/authApi';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  removeLocalStorage, removeSessionStorage, setLocalStorage, setSessionStorage,
} from 'utility/browserStorageUtil';
import moment from 'moment';
import {
  setIsAuth, setLoading,
} from '../redux/auth/actions';

const useAuthCustomer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyCode = window.location.pathname.split('/')[1];

  const signin = async (email: string, password: string) => {
    localStorage.clear();
    sessionStorage.clear();
    const params = {
      email,
      password,
    };
    try {
      // reset to default
      dispatch(setIsAuth(false));
      dispatch(setLoading(true));

      // BEGIN::Setting to refresh token
      const {
        accessToken, refreshToken, expiresIn, customerId,
      } = await loginCustomer(companyCode, params);
      // Save history access login
      const paramsPost = { accessUrl: `/${companyCode}/mypage/login`, companyCode, customerBasicDataId: customerId };
      await accessHistoryApi(paramsPost);
      setSessionStorage('companyCode', companyCode);
      setLocalStorage('accessToken', accessToken || '');
      setLocalStorage('customerId', customerId);
      setLocalStorage('customer', email); // Math.floor(Number(expiresIn) / 60)
      setLocalStorage('expiredToken', moment().utcOffset(9).add(Math.floor(Number(expiresIn) / 60), 'minutes').format('YYYY-MM-DD HH:mm:ss'));
      setLocalStorage('accessRefreshToken', refreshToken || '');
      dispatch(setIsAuth(true));
      return companyCode;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const signup = (email: string, password: string) => {
    //
  };

  const signout = () => {
    dispatch(setIsAuth(false));
    removeSessionStorage('companyCode');
    removeLocalStorage('accessToken');
    removeLocalStorage('customerId');
    removeLocalStorage('user');
    removeLocalStorage('accessRefreshToken');
    removeLocalStorage('expiredToken');
    history.push(`/${companyCode}/mypage/login`);
  };

  return {
    signin,
    signup,
    signout,
  };
};

export default useAuthCustomer;
