import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSessionStorage } from 'utility/browserStorageUtil';
import useAuthCustomer from 'hooks/useAuthCustomer';
import useStyles from './style';

export interface MenusProps {
  openCloseHandler: () => void
}

const Menus = (p: MenusProps) => {
  const history = useHistory();
	const companyCode = getSessionStorage('companyCode');
  const classes = useStyles();
  const { signout } = useAuthCustomer();
  return (
    <div className={classes.root}>
      <IconButton className={classes.closeIconButton} onClick={p.openCloseHandler}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <div className={classes.mainMenuWrapper}>
        <Typography
          className={classes.firstText}
          variant="h5"
          variantMapping={{ h5: 'h1' }}
          onClick={() => {
            history.push(`/${companyCode}/mypage/customer/info`);
          }}
        >
          お客様情報
        </Typography>
        <Typography
          className={classes.text}
          variant="h5"
          variantMapping={{ h5: 'h1' }}
          onClick={() => {
            history.push(`/${companyCode}/mypage/customer/measurementInfo`);
          }}
        >
          採寸データ
        </Typography>
      </div>
      <Typography
        className={classes.text}
        variant="h5"
        variantMapping={{ h5: 'h1' }}
        onClick={() => {
          history.push(`/${companyCode}/mypage/customer/changePassword`);
        }}
      >
        パスワード変更
      </Typography>
      <Typography
        className={classes.text}
        variant="h5"
        variantMapping={{ h5: 'h1' }}
        onClick={signout}
      >
        ログアウト
      </Typography>
    </div>
  );
};

export default Menus;
