import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import useStyles from './style';
import Menus from '../Menus';

type AppBarType = {
  title: string
}
function Topbar({ title }: AppBarType) {
  const [openMenu, setOpenMenu] = useState(false);
  const classes = useStyles();
  const openCloseHandler = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      {openMenu ? <Menus openCloseHandler={openCloseHandler} /> : (
        <AppBar className={classes.root} position="fixed">
          {title !== '' && (
            <Toolbar className={classes.toolbar}>
              <Box display="flex" alignItems="center">
                <IconButton
                  edge="end"
                  color="inherit"
                  className={classes.iconButton}
                  onClick={openCloseHandler}
                >
                  <MenuIcon />
                </IconButton>
                <Typography className={classes.text} variant="h5" variantMapping={{ h5: 'h1' }}>
                  {title}
                </Typography>
              </Box>
            </Toolbar>
          )}
        </AppBar>
      )}
    </>
  );
}

export default Topbar;
