import PrivateRoute from 'router/PrivateRoute';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Appbar from 'components/organismos/Appbar';
import SidebarWrapperTemplate from 'components/templates/SidebarWrapperTemplate';
import { privateRoutes, publicRoutes } from './routes';
import { flattenRouteArr } from './routeMethods';

/**
 * Render list of public Routes.
 * Always require :companyCode
 * @returns JSX.Element[]
 */
export const renderPublicRoutes = () => publicRoutes.map(({ path, ...rest }, i) => {
  if (path === '/contact/reservem/'
    || path === '/contact/reserve/'
    || path === '/mypage/login'
    || path === '/mypage/registration'
    || path === '/mypage/setNewPassword'
    || path === '/mypage/customer/contractList'
    || path === '/mypage/customer/reservationList'
    || path === '/mypage/resetPassword') {
    return (
      <Route key={path + String(i)} exact={true} path={path} {...rest}>
        <rest.loadComponent />
      </Route>
    );
  }
  return (
    <Route key={path + String(i)} exact={true} path={`/:companyCode${path}`} {...rest}>
      <rest.loadComponent />
    </Route>
  );
});

/**
 * Return a PrivateRoutes wrapper with a list of Routes inside.
 * Always require :companyCode
 * @returns JSX.Element
 */
export const renderPrivateRoutes = () => {
  const getStyle = (path: string) => (
    {
      minHeight: '100vh',
      backgroundColor: path.includes('pos') ? '#FFF8EE' : '#FFF',
      width: '1798px',
    }
  );

  return (
    <PrivateRoute path="/:companyCode">
      <SidebarWrapperTemplate>

        <Switch>
          {flattenRouteArr(privateRoutes).map(({
            path, title, hasAppbar = true, ...rest
          }) => (
            <Route key={path} path={`/:companyCode${path}`} exact={true} {...rest}>
              {hasAppbar
                ? (
                  <>
                    <Box position="relative">
                      <Appbar title={title} />
                      <Box
                        paddingTop={10}
                        paddingX={4}
                        style={getStyle(path)}
                      >
                        <rest.loadComponent />
                      </Box>
                    </Box>
                  </>
                )
                : <rest.loadComponent />}
            </Route>
          ))}

          {/* If not match, redirect to 404 */}
          <Redirect to="/:companyCode/404" />
        </Switch>

      </SidebarWrapperTemplate>
    </PrivateRoute>
  );
};
