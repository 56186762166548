import { Box, LinearProgress } from '@material-ui/core';
import Sidebar from 'components/organismos/Sidebar';
import React, { Suspense } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { privateRoutes } from 'router/routes';

/**
 * Sidebar on the left.
 * All pages are rendered on the right.
 * Used in PrivateRoute.
 */
const SidebarWrapperTemplate: React.FC = ({ children }) => {
  const pageWithoutSidebar = privateRoutes.filter((route) => route.hasAppbar === false).map((r) => r.path);// url of page
  const location = useLocation();
  const { companyCode } = useParams<{ companyCode: string }>();

  return (
    <Box display="flex">
      {pageWithoutSidebar.includes(location.pathname.replace(`/${companyCode}`, '')) ? <></> : <Sidebar />}
      <Suspense fallback={<LinearProgress />}>
        {children}
      </Suspense>
    </Box>
  );
};

export default SidebarWrapperTemplate;
