import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  createStyles, makeStyles, Theme, ThemeProvider,
} from '@material-ui/core/styles';
import globalTheme from 'components/GlobalTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';
import App from './App';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& > *': {
      fontSize: '18px !important',
    },
  },
}));


const MainComponent: React.FC = () => {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={globalTheme}>
        <SnackbarProvider
          classes={{
            root: classes.root,
          }}
          maxSnack={6}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {/* apply Normalize.css */}
          <CssBaseline />

          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

ReactDOM.render(
  <MainComponent />, document.getElementById('root'),
);
