import ApiClient from './ApiClient';

export const getRegisterApi = async (companyCode: string, params: any): Promise<any> => {
  const { data } = await ApiClient.get(`/customer/register/checkEmailRegister/${companyCode}`, {}, params);
  return data;
};

export const postRegisterApi = async (companyCode: string, params: any): Promise<any> => {
  const { data } = await ApiClient.postJsonData(`/customer/register/${companyCode}`, {}, params);
  return data;
};

export const getResetPasswordApi = async (companyCode: string, params: any): Promise<any> => {
  const { data } = await ApiClient.get(`/customer/register/checkEmailResetPassword/${companyCode}`, {}, params);
  return data;
};


