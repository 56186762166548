import { makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'white',
    width: '100vw',
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
    margin: '0 1rem',
    fontWeight: 700,
  },
  iconButton: {
    marginRight: '2rem',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
export default useStyles;
