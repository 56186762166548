import { StaffDetailType, RolesType } from 'types/authType';

export const types = {
  SET_IS_AUTH: 'SET_IS_AUTH' as const,
  SET_LOGIN_INFO: 'SET_LOGIN_INFO' as const,
  SET_ROLE: 'SET_ROLE' as const,
  LOGIN_ERROR: 'LOGIN_ERROR' as const,
  SET_LOADING: 'SET_LOADING' as const,
  SET_PAGES_ACCESS: 'SET_PAGES_ACCESS' as const,
  SET_STAFF_DETAIL: 'SET_STAFF_DETAIL' as const,
};

export const setIsAuth = (payload: boolean) => ({
  type: types.SET_IS_AUTH,
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: types.SET_LOADING,
  payload,
});

export const loginError = (payload: string[]) => ({
  type: types.LOGIN_ERROR,
  payload,
});

export const setStaffDetail = (payload: {
  staffDetail: StaffDetailType,
  roles: RolesType,
  error?: []
}) => ({
  type: types.SET_STAFF_DETAIL,
  payload,
});

export type Actions = (
  | ReturnType<typeof setIsAuth>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof loginError>
  | ReturnType<typeof setStaffDetail>
);
